<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<TopMenuBar>
					<TopMenuGroup label="Options" :hasdropdown="true">
						<TopMenuItem label="Save List" @click="saveList"></TopMenuItem>
						<TopMenuItem label="Sort by Artist" @click="sortList('artist')"></TopMenuItem>
						<TopMenuItem label="Sort by Title" @click="sortList('title')"></TopMenuItem>
						<TopMenuItem label="Sort by Year" @click="sortList('year')"></TopMenuItem>
						<TopMenuItem label="Decade Breakdown" @click="decadeBreakdown"></TopMenuItem>
						<TopMenuItem label="Finalise List" @click="saveList(true)"></TopMenuItem>
						<TopMenuItem label="Print to Excel" @click="printListToExcel"></TopMenuItem>
						<TopMenuItem label="Import GSelector CSV" @click="importGSelBrowse"></TopMenuItem>
						<TopMenuItem label="Default Media IDs" @click="manageDefaultIds"></TopMenuItem>
					</TopMenuGroup>
					<p style="color: #FFF; margin: 28px 0 0 500px;" v-if="hooklistOb">{{hooklistOb.nickname}}</p>
				</TopMenuBar>
				<HooksBrowseListUpload v-if="showCSVUploader" :chosenstationname="hooklistOb.station"
					@close="handleCSVReturn($event)"
				></HooksBrowseListUpload>
				<HookMapToMediaId v-if="showMappingPopup" :chosenstationname="hooklistOb.station"
					:mappedhooks="csvMaps" :suggestedhooks="csvSuggests"
					@close="closeHookMapper" @addhook="handleMappingAdd($event)"
				></HookMapToMediaId>
				<HookMapToMediaIdManual v-if="showMappingPopupManual" :chosenstationname="hooklistOb.station"
					:missinghooks="itemsWithoutMediaId" @close="showMappingPopupManual = false"
					@updatehook="addMapping($event)"
				></HookMapToMediaIdManual>
				<ManageDefaultIds v-if="showManageDefaultIds" :chosenstationname="hooklistOb.station"
					:hooklib="hookLibrary" @close="showManageDefaultIds = false"
					@updatehook="addMapping($event)"
				></ManageDefaultIds>

				<div id="savebox" v-if="showSavedSuccess === true">Saved</div>
				<div id="hookselect-holder" class="wl-admin-container maincontent">
					<div id="mainlibcontent">
						<div id="hookexplorer">
							<div style="overflow: hidden; position: relative;">
								<h5>Hook Library</h5>
								<div id="playerholder">
									<audio src="" controls="" id="player"></audio>
								</div>
							</div>
							<div id="search">
								<input id="searchfield" ref="searchfield" type="text" v-model="filterTextLibrary">
							</div>
							<div id="explorerlist">
								<ul id="library">
									<li v-for="h in rowsForPage" class="ul_item" :key="h.id" :class="{markedhook : selectedHookIdArr.includes(h.id)}">
										<span class="hooktext">{{h.artist}} - {{h.title}} ({{h.year}})</span>
										<div class="iconshold">
											<span class="medidhook" :class="{hasMediaId : h.mediaId}" @click="mediaIdHook(h)"><i class="mdi mdi-identifier" :title="'GSel Media ID: '+h.mediaId"></i></span>
											<span class="addhook" @click="addtolist(h)"><i class="mdi mdi-plus" title="Add hook to list"></i></span>
											<span class="playhook" @click="playHook(h)"><i class="mdi mdi-play" title="Play hook audio"></i></span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div id="list">
						<h5>Hooks Selected
							<span id="countdiv">
								{{selectedHookObs.length}} hooks chosen.
								<span @click.stop="showMappingPopupManual = true" class="text-link">{{itemsWithoutMediaId.length}} without MediaId</span>
							</span>
						</h5>
						<input type="text" id="listsearchfield" v-model="filterTextList">
						<div id="selection-list">
							<draggable tag="ol" v-model="selectedHookObs" item-key="hook" handle=".handle" class="listol">
								<template #item="{element, index}">
									<li v-if="filteredSelectedHookIds.includes(element.id)" :key="element.id" class="li_item handle" :data-ind="index">
										<span class="hooktext">{{element.artist}} - {{element.title}} ({{element.year}})</span>
										<div class="iconshold">
											<span class="medidhook" :class="{hasMediaId : element.mediaId}" @click="mediaIdHook(element)"><i class="mdi mdi-identifier" :title="'GSel Media ID: '+element.mediaId"></i></span>
											<span class="drophook" @click="removehook(element)"><i class="mdi mdi-close" title="Remove hook from list"></i></span>
											<span class="playhook" @click="playHook(element)"><i class="mdi mdi-play" title="Play hook audio"></i></span>
										</div>
									</li>
								</template>
							</draggable>
<!--							<ol v-if="filterTextList.length > 0" class="listol">-->
<!--								<li v-for="h in filteredSelectedHookObs" :key="h.id" class="li_item" style="cursor: default;">-->
<!--									<span class="hooktext">{{h.artist}} - {{h.title}} ({{h.year}})</span>-->
<!--									<div class="iconshold">-->
<!--										<span class="medidhook" :class="{hasMediaId : h.mediaId}" @click="mediaIdHook(h)"><i class="mdi mdi-identifier" title="Media ID"></i></span>-->
<!--										<span class="drophook" @click="removehook(h)"><i class="mdi mdi-close" title="Remove hook from list"></i></span>-->
<!--										<span class="playhook" @click="playHook(h)"><i class="mdi mdi-play" title="Play hook audio"></i></span>-->
<!--									</div>-->
<!--								</li>-->
<!--							</ol>-->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import TopMenuBar from "@/components/TopMenuBar";
import draggable from "vuedraggable";
import TopMenuGroup from "@/components/TopMenuGroup.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import HooksBrowseListUpload from "@/components/hooks-admin/HooksBrowseListUpload.vue";
import HookMapToMediaId from "@/components/hooks-admin/HookMapToMediaId.vue";
import HookMapToMediaIdManual from "@/components/hooks-admin/HookMapToMediaIdManual.vue";
import ManageDefaultIds from "@/components/hooks-admin/ManageDefaultIds.vue";

export default {
	name: "HookSelectPage",
	mixins: [globalMixin],
	components: {
		ManageDefaultIds,
		HookMapToMediaId,
		HookMapToMediaIdManual,
		HooksBrowseListUpload,
		TopMenuItem,
		TopMenuGroup,
		TopMenuBar,
		LeftMenuWL,
		draggable,
	},
	props: {},
	data: function () {
		return {
			store: store,

			chosenListId: null,
			isFetching: false,

			hookLibrary: [],
			hooklistOb: null,

			selectedHookObs: [],
			filterTextLibrary: '',
			filterTextList: '',
			paginationRowsPerPage: 100,
			paginationPage: 1,
			activeHookOb: null,

			showSavedSuccess: false,
			saverTimeout: null,

			showCSVUploader: false,
			csvMaps: [],
			csvSuggests: [],

			showMappingPopup: false,
			showMappingPopupManual: false,

			showManageDefaultIds: false,
		}
	},
	computed: {
		itemsWithoutMediaId() {
			return this.selectedHookObs.filter(item => !item.mediaId)
		},
		hookLibraryFiltered() {
			return this.tblPrepareFilteredRows(this.hookLibrary, this.filterTextLibrary, null, null)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.hookLibraryFiltered, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.hookLibraryFiltered]
			return this.paginatedRows[this.paginationPage-1]
		},
		selectedHookIdArr() {
			let r = []
			for(let ob of this.selectedHookObs) {
				r.push(ob.id)
			}
			return r
		},
		fileFullUrl() {
			if(this.activeHookOb) {
				return "https://yourwavelength.com/hooklib/" + this.activeHookOb.id + ".mp3";
			}
			return ''
		},
		filteredSelectedHookObs() {
			return this.tblPrepareFilteredRows(this.selectedHookObs, this.filterTextList, null, null)
		},
		filteredSelectedHookIds() {
			let r = []
			for(let ob of this.filteredSelectedHookObs) {
				r.push(ob.id)
			}
			return r
		},
	},
	methods: {
		importGSelBrowse() {
			this.showCSVUploader = true
		},
		manageDefaultIds() {
			this.showManageDefaultIds = true
		},
		handleCSVReturn(ret) {
			if(ret) {
				this.csvMaps = ret.maps
				for (let item of this.csvMaps) {
					this.addToListById(item.hookId)
				}
				this.csvSuggests = ret.suggestions
			}
			this.showCSVUploader = false
			if(ret) this.showMappingPopup = true
		},
		closeHookMapper() {
			this.showMappingPopup = false
		},
		mediaIdHook(hob) {
			let oldval = ''
			if (hob.mediaId) oldval = hob.mediaId
			let prompt = window.prompt("Enter Media ID for "+hob.artist+" - "+hob.title+" ("+hob.year+")", oldval)
			if(prompt !== null) {
				let targ = this.store.urlroot + "central/post-vue/hooks/assign-media-map"
				let sendob = {
					hookId: hob.id,
					mediaId: prompt,
					station: this.hooklistOb.station
				}
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					this.updateLibWithMediaId(hob.id, prompt)
				}.bind(this))
			}
		},
		playHook(rowitem) {
			this.activeHookOb = rowitem
			let a = document.getElementById('player')
			a.src = this.fileFullUrl
			a.play()
		},
		addtolist(hob) {
			if(!this.selectedHookObs.find(item => item.id === hob.id)) {
				this.selectedHookObs.push(hob)
			}
			this.filterTextLibrary = ''
			this.$refs.searchfield.focus()
		},
		updateLibWithMediaId(hookId, mediaId) {
			let hob = this.hookLibrary.find(item => item.id === hookId)
			if(hob) {
				hob.mediaId = mediaId
			}
		},
		addMapping(arr) {
			let hookid = arr[0]
			let mediaid = arr[1]
			this.updateLibWithMediaId(hookid, mediaid)
		},
		handleMappingAdd(arr) {
			this.addMapping(arr)
			this.addToListById(arr[0])
		},
		addToListById(hookId) {
			let ob = this.hookLibrary.find(item => item.id === hookId)
			if(ob) this.addtolist(ob)
		},
		removehook(hob) {
			let index = this.selectedHookObs.findIndex(item => item.id === hob.id)
			if(index > -1) {
				this.selectedHookObs.splice(index, 1)
			}
		},
		getLibrary() {
			if(!this.isFetching) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/hooks/get-all-hooks"
				let sendob = {
					station: this.hooklistOb.station
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.hooks) this.hookLibrary = ret.hooks
					this.store.showLoader = false
					this.handleListPopulate()
				}.bind(this))
			}
		},
		getHooklist() {
			if(!this.chosenListId || this.chosenListId === 0) {
				this.$router.push('/hook-lists')
				return false
			}
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/hooks/get-hooklist"
			let sendob = {listId: this.chosenListId}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.hooklist) {
					this.hooklistOb = ret.hooklist
					this.getLibrary()
				}
				this.store.showLoader = false
			}.bind(this))
		},
		handleListPopulate() {
			this.selectedHookObs = []
			for(let hid of this.hooklistOb.hookIds) {
				let ob = this.hookLibrary.find(item => item.id === hid)
				if(ob) this.selectedHookObs.push(ob)
			}
			this.startSaveTimeout()
		},
		saveList(finalise = false) {
			if(!this.isFetching && (!this.hooklistOb || this.hooklistOb.id > 0)) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/hooks/save-hooklist-hooks"
				let sendob = {
					listId: this.hooklistOb.id,
					hookIds: this.selectedHookIdArr,
				}
				if(finalise === true) this.store.showLoader = true
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(finalise === true) {
						this.finaliseList()
					}
					else {
						if(ret.success) this.triggerSavedSuccessShow()
						this.store.showLoader = false
						this.startSaveTimeout()
					}
				}.bind(this))
			}
		},
		triggerSavedSuccessShow() {
			this.showSavedSuccess = true
			setTimeout(function() {
				this.showSavedSuccess = false
			}.bind(this), 5000)
		},
		startSaveTimeout() {
			if(this.saverTimeout) clearTimeout(this.saverTimeout)
			this.saverTimeout = setTimeout(function() {
				this.saveList()
			}.bind(this), 180000)
		},
		finaliseList() {
			if(!this.isFetching && (!this.hooklistOb || this.hooklistOb.id > 0)) {
				let c = confirm("This will finalise the current list and you will not be able to edit the hooks any further, are you sure?")
				if (c === true) {
					this.isFetching = true
					let targ = this.store.urlroot + "central/post-vue/hooks/finalise-list"
					let sendob = {
						listId: this.hooklistOb.id,
					}
					this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
						this.isFetching = false
						this.store.showLoader = false
						alert("Saved successfully")
						this.$router.push('/hook-lists')
					}.bind(this))
				}
			}
		},
		decadeBreakdown() {
			let E60 = 0, E70 = 0, E80 = 0, E90 = 0, E00 = 0, E10 = 0, E20 = 0
			for(let ob of this.selectedHookObs) {
				let year = parseInt(ob.year)
				if (year >= 1960 && year < 1970) E60 = E60 + 1
				else if (year >= 1970 && year < 1980) E70 = E70 + 1
				else if (year >= 1980 && year < 1990) E80 = E80 + 1
				else if (year >= 1990 && year < 2000) E90 = E90 + 1
				else if (year >= 2000 && year < 2010) E00 = E00 + 1
				else if (year >= 2010 && year < 2020) E10 = E10 + 1
				else if (year >= 2020 && year < 2030) E20 = E20 + 1
			}
			let total = E60 + E70 + E80 + E90 + E00 + E10 + E20
			alert("60s="+E60+" ("+parseInt(E60/total*100)+"%), " +
				"70s="+E70+" ("+parseInt(E70/total*100)+"%), " +
				"80s="+E80+" ("+parseInt(E80/total*100)+"%), " +
				"90s="+E90+" ("+parseInt(E90/total*100)+"%), " +
				"00s="+E00+" ("+parseInt(E00/total*100)+"%), " +
				"10s="+E10+" ("+parseInt(E10/total*100)+"%)" +
				"20s="+E20+" ("+parseInt(E20/total*100)+"%)"
			);

		},
		sortList(sorttype) { //artist, title, year
			let c = confirm("This will sort your list by "+this.ucfirst(sorttype)+" - you cannot undo this process.  If your list isn't going to be randomised on playback you should not do this.  Are you sure?")
			if(c === true) {
				if(sorttype === 'year') {
					this.selectedHookObs.sort(function (a, b) {
						let x = a[sorttype]
						let y = b[sorttype]
						if (x < y) return -1
						if (x > y) return 1
						return 0;
					})
				}
				else {
					this.selectedHookObs.sort(function (a, b) {
						let x = a[sorttype].toLowerCase()
						let y = b[sorttype].toLowerCase()
						if (x < y) return -1
						if (x > y) return 1
						return 0;
					})
				}
			}
		},
		printListToExcel() {
			window.location = this.store.urlroot+'central/post-vue/hooks/print-hooklist-to-excel?uid='
				+this.store.user.id+'&wlsesstoken='+this.store.wlsesstoken
				+'&listid='+this.hooklistOb.id
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.hookcentral !== 1) {
				this.$router.push('/error/no-access')
			}
		},
		chosenListId() {
			if(this.chosenListId && this.chosenListId > 0) {
				this.getHooklist()
			}
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.hookcentral !== 1) {
			this.$router.push('/error/no-access')
		}
	},
	created() {
		document.title = "Hook Selections"
		this.store.activeLmenuLink = ''
		let urlparams = this.$route.params
		if(urlparams.hooklistid !== undefined) {
			this.chosenListId = parseInt(urlparams.hooklistid)
		}
	},
	beforeUnmount() {
		if (this.saverTimeout) clearTimeout(this.saverTimeout)
	},
}
</script>

<style scoped>
.maincontent {
	margin-top: 70px;
	height: 100%;
}
#hookselect-holder {
	height: calc(100vh - 130px);
	display: flex;
	gap: 20px;
}
#hookexplorer {
	width: 440px;
	height: 100%;
	margin: 0;
	background-color: #F1F2F6;
	position: relative;
}
#hookexplorer h5 {
	display: inline-block;
	float: left;
}
#explorerlist {
	width: 425px;
	height: calc(100% - 100px);
	position: relative;
	margin: 0 15px 0;
	overflow: hidden;
}
#selection-list {
	height: calc(100% - 100px);
	position: relative;
}
#library {
	width: 370px;
	overflow: hidden;
}
#list {
	overflow: hidden;
	margin: 0;
	width: 440px;
	height: 100%;
	display: inline-block;
	padding: 0 0 0 5px;
	border: none;
	background: #DEF5EB;
	position: relative;
}
#countdiv {
	margin: -25px 290px;
	width: 200px;
	font-size: 10px;
}
#search {
	position: relative;
	width: auto;
}
#library, .listol {
	height: 100%;
	position: relative;
	overflow-y: scroll;
	width: 425px;
	margin-top: 12px;
	-webkit-padding-start: 0;
	-moz-padding-start: 0;
	padding-left: 0;
}
.listol {
	margin-top: 3px;
	width: 437px;
}
.ul_item {
	list-style-type: none;
	width: 390px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#mainlibcontent {
	width: 460px;
	height: 100%;
}
#library li, .listol li {
	font-size: 11px;
	margin-bottom: 4px;
	padding: 4px;
	border: none;
}
.markedhook {
	color: #2AAEF6;
}
.listol li {
	cursor: move;
	margin-right: 6px;
}
.hooktext {
	width: 300px;
	overflow: hidden;
	display: block;
	white-space: nowrap;
}
.iconshold {
	display: flex;
	gap: 8px;
	align-items: center;
}
.addhook, .playhook, .drophook, .medidhook {
	font-size: 18px;
	cursor: pointer;
	font-weight: bold;
}
.playhook {
	color: #2BAEF6;
}
.addhook {
	color: #00BC6C;
}
.drophook {
	color: #FF0000;
}
.medidhook {
	color: #0b1b3f;
}
.medidhook.hasMediaId {
	color: #00BC6C;
}
h5 {
	font-size: 17px;
	font-family: 'Roboto Slab', Roboto, Arial;
	font-weight: bold;
	line-height: 1.5;
	margin: 0 0 10px 20px;
	padding-top: 10px;
}
#countdiv {
	font-size: 12px;
	font-weight: normal;
	margin: 0;
	width: auto;
}
#searchfield, #listsearchfield {
	width: 390px;
	margin: 5px 5px 5px 15px;
	padding: 5px;
	font-size: 13px;
}
#playerholder {
	height: 30px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 10px;
	right: 10px;
}
#player {
	width: 150px;
	height: 25px;
}
#savebox {
	position: fixed;
	z-index: 990; /*behind blanket */
	border: 1px #888 solid;
	border-radius: 5px;
	font-family: Roboto, arial;
	font-size: 12px;
	color: #FFF;
	background-color: rgba(0,0,0,0.6);
	margin: 40px 0 0 15px;
	padding: 10px 20px;
	right: 150px;
	transition: all 0.5s ease-in-out;
}
#hsinfo {
	margin: 25px 0 0 520px;
	color: #FFF;
	font-family: Roboto, Arial;
	font-weight: bold;
}
.li_item {
	margin-left: 15px;
	width: 390px;
	background-color: #FFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: inside;
	list-style-type: none;
}
.filterOut {
	display: none;
}

</style>